import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import './MessageLog.css';

// Register chart components with ChartJS
ChartJS.register(...registerables);

function MessageLog() {
  const [dropdownLogs, setDropdownLogs] = useState([]);
  const [filteredDropdownLogs, setFilteredDropdownLogs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [initialInvestment, setInitialInvestment] = useState(10000); // Initial investment amount

  useEffect(() => {
    const loadLogs = async () => {
      try {
        const response = await axios.get('https://rollin-trades-announcement-app.onrender.com/logs');
        const logs = response.data || [];
        setDropdownLogs(logs);
        setFilteredDropdownLogs(logs);
      } catch (error) {
        console.error('Error loading logs:', error);
      }
    };

    loadLogs();
  }, []);

  useEffect(() => {
    const filterLogsByDateRange = (logs) => {
      if (!startDate || !endDate) return logs;

      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      endDateObj.setHours(23, 59, 59, 999);

      return logs.filter(log => {
        const logDate = new Date(log.timestamp);
        return logDate >= startDateObj && logDate <= endDateObj;
      });
    };

    setFilteredDropdownLogs(filterLogsByDateRange(dropdownLogs));
  }, [startDate, endDate, dropdownLogs]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const calculateTotalProfit = (BoughtPrice, SoldTransactions) => {
    if (!Array.isArray(SoldTransactions) || SoldTransactions.length === 0) return 0;

    return SoldTransactions.reduce((totalProfit, Sold) => {
      const SoldPositionSize = parseFloat(Sold.SoldPositionSize) || 1;
      return totalProfit + (Sold.SoldPrice - BoughtPrice) * SoldPositionSize;
    }, 0);
  };

  const calculateTotalReturn = (BoughtPrice, totalProfit) => {
    if (BoughtPrice === 0) return '0.00'; // Avoid division by zero
    return ((totalProfit / BoughtPrice) * 100).toFixed(2);
  };

  const groupLogs = (logs) => {
    if (!Array.isArray(logs)) return [];

    const groupedLogs = {};

    logs.forEach(log => {
      const { action, ticker, strikePrice, optionType, expiryDate, price, timestamp, SoldTransactions } = log;
      const logKey = `${ticker}-${strikePrice}-${optionType}-${expiryDate}`;

      if (!groupedLogs[logKey]) {
        groupedLogs[logKey] = {
          ticker,
          strikePrice,
          optionType,
          expiryDate: new Date(expiryDate).toLocaleDateString(),
          BoughtPrice: null,
          BoughtTimestamp: null,
          SoldTransactions: []
        };
      }

      if (action === 'Bought') {
        groupedLogs[logKey].BoughtPrice = price;
        groupedLogs[logKey].BoughtTimestamp = new Date(timestamp).toLocaleString();
      } else if (action === 'Sold' && SoldTransactions && SoldTransactions.length > 0) {
        groupedLogs[logKey].SoldTransactions.push(...SoldTransactions);
      }
    });

    return Object.values(groupedLogs);
  };

  const renderSoldTransactions = (SoldTransactions) => {
    return SoldTransactions.map((Sold, idx) => (
      <div key={idx} className="Sold-transaction">
        {`[${Sold.SoldPositionSize} @ $${Sold.SoldPrice} on ${new Date(Sold.SoldTimestamp).toLocaleString()}]`}
      </div>
    ));
  };

  const renderLogRow = (log, index) => {
    const { ticker, strikePrice, optionType, expiryDate, BoughtPrice, BoughtTimestamp, SoldTransactions } = log;
    const totalProfit = calculateTotalProfit(BoughtPrice, SoldTransactions);
    const percentageReturn = `${calculateTotalReturn(BoughtPrice, totalProfit)}%`;

    const rowClass = parseFloat(totalProfit) >= 0 ? 'profit-row' : 'loss-row';

    return (
      <tr key={index} className={rowClass}>
        <td>{ticker}</td>
        <td>{strikePrice}</td>
        <td>{optionType}</td>
        <td>{expiryDate}</td>
        <td>{BoughtPrice !== null ? `$${BoughtPrice.toFixed(2)}` : 'N/A'}</td>
        <td>{`$${parseFloat(totalProfit).toFixed(2)}`}</td>
        <td>{percentageReturn}</td>
        <td>{BoughtTimestamp || 'N/A'}</td>
        <td>{SoldTransactions.length > 0 ? renderSoldTransactions(SoldTransactions) : 'N/A'}</td>
      </tr>
    );
  };

  const calculateGrandTotal = (logs) => {
    if (!Array.isArray(logs)) return '0.00';

    return logs.reduce((totalProfit, log) => {
      return totalProfit + parseFloat(calculateTotalProfit(log.BoughtPrice, log.SoldTransactions));
    }, 0).toFixed(2);
  };

  const calculateWealthOverTime = (logs) => {
    let wealth = initialInvestment;
    const wealthData = logs.map(log => {
      const totalProfit = parseFloat(calculateTotalProfit(log.BoughtPrice, log.SoldTransactions));
      wealth += totalProfit;
      return wealth.toFixed(2);
    });

    return wealthData;
  };

  const groupedLogs = groupLogs(filteredDropdownLogs);

  // Prepare data for the ROI chart
  const barChartData = {
    labels: groupedLogs.map(log => `${log.ticker} ${log.strikePrice} ${log.optionType}`),
    datasets: [
      {
        label: 'Return on Investment (%)',
        data: groupedLogs.map(log => calculateTotalReturn(log.BoughtPrice, calculateTotalProfit(log.BoughtPrice, log.SoldTransactions))),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  const wealthChartData = {
    labels: groupedLogs.map(log => `${log.ticker} ${log.strikePrice} ${log.optionType}`),
    datasets: [
      {
        label: 'Wealth Over Time',
        data: calculateWealthOverTime(groupedLogs),
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
      }
    ]
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Return (%)',
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Return on Investment by Contract',
        font: {
          size: 18,
        },
        color: '#333',
      }
    }
  };

  // Determine the heading dynamically based on the date range
  const getTableHeading = () => {
    if (!startDate || !endDate) return 'Messages';

    const start = new Date(startDate);
    const end = new Date(endDate);
    const dayDifference = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;

    if (dayDifference === 7) return 'Weekly Messages';
    if (dayDifference === 14) return 'Biweekly Messages';
    return `${dayDifference}-Day Messages`;
  };

  return (
    <div className="log-container">
      <h2>Logs</h2>

      <label>
        Start Date:
        <input type="date" value={startDate} onChange={handleStartDateChange} />
      </label>
      <label>
        End Date:
        <input type="date" value={endDate} onChange={handleEndDateChange} />
      </label>

      <div className="table-container">
        <div className="log-table">
          <h3>{getTableHeading()}</h3>
          <table>
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Strike</th>
                <th>Type</th>
                <th>Expiry Date</th>
                <th>Bought Price</th>
                <th>Total Profit</th>
                <th>Total Return (%)</th>
                <th>Bought Time</th>
                <th>Sold Transactions</th>
              </tr>
            </thead>
            <tbody>
              {groupedLogs.map(renderLogRow)}
              <tr>
                <td colSpan={5}>Grand Total</td>
                <td colSpan={4}>{`$${calculateGrandTotal(groupedLogs)}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="chart-container">
        {barChartData?.datasets?.length > 0 && <Bar data={barChartData} options={chartOptions} />}
        {wealthChartData?.datasets?.length > 0 && <Line data={wealthChartData} options={{ scales: { y: { beginAtZero: true } } }} />}
      </div>
    </div>
  );
}

export default MessageLog;
